.field__select.select {
  height: $st-input-height;

  &:focus-within {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
  }

  &.multiple {
    &:after {
      display: none;
    }

    height: auto;

    select {
      height: auto;
      padding: 10px;
      overflow: auto;
    }
  }

  select {
    padding-left: 10px;
    background: #fff;
    height: $st-input-height - 2; // select height - 2 for border
  }
}

@media screen {
  .disabled {
    .field__select {
      select {
        background: $disabled-bg;
      }

      &::after {
        display: none;
      }
    }
  }
}

@media print {
  &::after {
    display: none;
  }
}

.has-error {
  .field__select {
    &.select {
      border-color: $brand-danger;
    }
  }
}
