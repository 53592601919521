
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.7);
  z-index: $zindex-modal-background;
  cursor: pointer;

  &.transparent {
    background: transparent;
  }

  &.mobile-only {
    @media (min-width: $screen-sm-min) {
      display: none;
    }
  }
}
