.field__label {
  font-weight: 700;
  margin-bottom: .3rem;

  .required-label {
    color: #9F3123;
  }

  .field__label-tooltip {
    margin-left: 8px;

    &:hover {
      > .ca-gov-icon-question-fill {
        color: $brand-primary;
        cursor: pointer;
      }
    }
  }
}
