.status-messages {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: -12px;
  right: 0;
  left: 0;
  margin: auto;
  z-index: $zindex-modal;
  width: 100%;

  > .card {
    > .dismiss-icon {
      top: 5px;
      right: 5px;
      position: absolute;
      cursor: pointer;
      font-size: 30px;
    }

    width: 100vw;
    @media (min-width: $screen-sm-min) {
      width: 500px;
    }
    @media (min-width: $screen-md-min) {
      width: 600px;
    }
  }
}
