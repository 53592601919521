.st-tooltip {
  font-size: inherit;
  font-weight: initial;
}

@media print {
  .field__label-tooltip {
    display: none;
  }
}
