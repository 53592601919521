.global-header {
  width: 100%;
  z-index: $zindex-navbar;

  .header-title {
    display: none;
    margin-top: 3px;

    @media (min-width: $screen-md-min) {
      display: block;
      font-size: 1.2em;
    }

    @media (min-width: $screen-md-min) {
      font-size: 1.4em;
    }
  }

  + .header-overlay {
    z-index: $zindex-navbar - 1;
  }

  &.fixed {
    z-index: $zindex-navbar-fixed;

    + .header-overlay {
      z-index: $zindex-navbar-fixed - 1;

      + .main-content {
        padding-top: 127px;
      }
    }
  }

  &.right {
    // align header icons to right in desktop view
    @media (min-width: 768px) {
      .top-level-nav {
        justify-content: flex-end;

        .nav-item {
          flex: 0 1 100px;
        }
      }
    }
  }
}

