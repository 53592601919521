/**
 * CA State Template v5 -  @version v5.0.10 -  7/30/2019 
  STYLES COMPILED FROM SOURCE (SCSS) DO NOT MODIFY */
/* -----------------------------------------
   OCEANSIDE
----------------------------------------- */
/* ----- OCEANSIDE PRIMARY THEME COLORS -----  */
.color-highlight, .color-p1 {
  color: #FDB81E;
}

.color-primary, .color-p2 {
  color: #046B99;
}

.color-standout, .color-p3 {
  color: #323A45;
}

.bg-highlight, .bg-p1 {
  background-color: #FDB81E !important;
}

.bg-primary, .bg-p2 {
  background-color: #046B99 !important;
}

.bg-standout, .bg-p3 {
  background-color: #323A45 !important;
}

/*  SECONDARY THEME COLORS	*/
.color-s1 {
  color: #E1F2F7;
}

.color-s2 {
  color: #9FDBF1;
}

.color-s3 {
  color: #50aee4;
}

.bg-s1 {
  background-color: #E1F2F7 !important;
}

.bg-s2 {
  background-color: #9FDBF1 !important;
}

.bg-s3 {
  background-color: #50aee4 !important;
}

/* -----------------------------------------
   LAYOUT
   /source/scss/colorscheme/layout.scss
----------------------------------------- */
/* Primary page with/without two-column */
.primary {
  /* Primary page with two column */
}

.primary.two-column {
  /* Main content contains 2col bg and footer bg */
}

.primary.two-column .main-content {
  background-color: #fff;
}

/* -----------------------------------------
   GLOBAL HEADER
   /source/scss/colorscheme/global-header.scss
----------------------------------------- */
.utility-header {
  background: #046B99;
  color: #ffffff;
}

.global-header {
  /* Default header pattern */
  /* Small screen gets solid color */
}

.explore-invite a:hover span[class^="ca-gov-icon-"] {
  color: #FDB81E;
}

.explore-invite a:hover .explore-title {
  color: #FDB81E;
}

/* -----------------------------------------
   GLOBAL FOOTER
   /source/scss/colorscheme/global-footer.scss
----------------------------------------- */
.global-footer {
  background: #222222;
}

/* Targets icon of first h1 on the page  */
h1 span[class^="ca-gov-icon-"]:first-child {
  color: #046B99;
}

/* -----------------------------------------
   NAVIGATION
   /source/scss/colorscheme/cs-navigation.scss
----------------------------------------- */
.navigation-search {
  border-bottom-color: #FDB81E;
}

.mobile-controls {
  background: #fff;
}

.mobile-control {
  color: #046B99;
}

.mobile-control a {
  color: #046B99;
  text-decoration: none;
}

.mobile-control.toggle-sub-nav {
  color: #046B99;
  background-color: #eef5f8;
}

.mobile-control.toggle-sub-nav:hover, .mobile-control.toggle-sub-nav:focus {
  background-color: #F5F9FB;
}

.nav-item.active > a, .nav-item:hover > a {
  color: #046B99;
}

.nav-item.active > a > span[class^="ca-gov-icon"], .nav-item:hover > a > span[class^="ca-gov-icon"] {
  color: #046B99;
}

.nav-arrow-down {
  background: #046B99;
}

.first-level-link {
  color: #555555;
}

.first-level-link > span[class^="ca-gov-icon"] {
  /* Navigation icons */
  color: #9fc7da;
}

@media (max-width: 767px) {
  .first-level-link {
    border-bottom: 1px solid #E1F2F7;
  }
}

.second-level-link {
  color: #046B99;
}

.second-level-link [class^="ca-gov-icon-"] {
  color: #046B99;
}

.second-level-nav > li {
  border: 1px solid transparent;
  border-right: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.second-level-nav > li:hover, .second-level-nav > li:focus {
  background: #fff;
}

@media (max-width: 767px) {
  .second-level-nav > li {
    border-right: none;
    border-bottom: 1px solid #E1F2F7;
  }
  .second-level-nav > li:hover, .second-level-nav > li:focus {
    background: #fcfdfe;
  }
}

.second-level-nav .link-description {
  color: #555555;
}

.sub-nav {
  border-bottom: 5px solid #046B99;
  border-left: 1px solid #d6d6d6;
  border-right: 1px solid #d6d6d6;
  background: #F5F9FB;
}

@media (max-width: 767px) {
  .sub-nav {
    border-top: none;
    border-bottom: none;
  }
}

.nav-media {
  background: transparent;
}

.nav-media .media:hover {
  background: #fff;
}

.nav-media .media {
  border-bottom: 1px solid #d6d6d6;
}

@media (max-width: 767px) {
  .nav-media .media {
    border-bottom: 1px solid #E1F2F7;
  }
  .nav-media .media:hover, .nav-media .media:focus {
    background: #fcfdfe;
  }
}

@media (min-width: 768px) {
  .nav-item::after {
    background-color: transparent;
  }
  .nav-item:hover::after, .nav-item:focus::after {
    background-color: #046B99;
  }
}

/* -----------------------------------------
   SEARCH
   /source/scss/colorscheme.search.scss
----------------------------------------- */
.search-container .search-textfield {
  background-color: rgba(255, 255, 255, 0.8);
}

.search-container .submit-container button {
  color: #046B99;
  border-color: rgba(255, 255, 255, 0.8);
}

.search-container:hover .search-textfield, .search-container:focus .search-textfield, .search-container.active .search-textfield {
  background-color: #fff;
}

.search-container:hover .submit-container button, .search-container:focus .submit-container button, .search-container.active .submit-container button {
  border-color: #fff;
}

.search-container.active {
  background: #d6d6d6;
}

@media (min-width: 768px) {
  .search-container.active {
    border-bottom: 2px solid #d6d6d6;
  }
}

.search-container.active .submit-container button {
  border-color: #bdbdbd;
  background: #555555;
}

.search-container.active .search-textfield {
  border-color: #bdbdbd;
}

.search-container.featured-search {
  background: none;
}

.search-container.featured-search:hover, .search-container.featured-search:focus {
  background: none;
}

.search-container.featured-search.active {
  background: #d6d6d6;
}

/* -----------------------------------------
   PANELS
   /source/scss/colorscheme/cs-panels.scss
----------------------------------------- */
/* Default Panels */
.panel-default {
  border-color: #ddd;
}

.panel-default > .panel-heading {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-default > .panel-heading .badge {
  color: #f5f5f5;
  background-color: #333333;
}

.panel-default > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-default .panel-heading h2 span[class^="ca-gov-icon-"], .panel-default .panel-heading h3 span[class^="ca-gov-icon-"], .panel-default .panel-heading h4 span[class^="ca-gov-icon-"], .panel-default .panel-heading h5 span[class^="ca-gov-icon-"] {
  color: #323A45;
}

.panel-default .panel-heading .options .btn-default {
  color: #212529;
  background-color: #fcfcfc;
  border-color: #ddd;
}

.panel-default .panel-heading .options .btn-default:hover {
  color: #212529;
  background-color: #e9e9e9;
  border-color: #c4c4c4;
}

.panel-default .panel-heading .options .btn-default:focus, .panel-default .panel-heading .options .btn-default.focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 194, 0.5);
}

.panel-default .panel-heading .options .btn-default.disabled, .panel-default .panel-heading .options .btn-default:disabled {
  color: #212529;
  background-color: #fcfcfc;
  border-color: #ddd;
}

.panel-default .panel-heading .options .btn-default:not(:disabled):not(.disabled):active, .panel-default .panel-heading .options .btn-default:not(:disabled):not(.disabled).active,
.show > .panel-default .panel-heading .options .btn-default.dropdown-toggle {
  color: #212529;
  background-color: #e3e3e3;
  border-color: #bdbdbd;
}

.panel-default .panel-heading .options .btn-default:not(:disabled):not(.disabled):active:focus, .panel-default .panel-heading .options .btn-default:not(:disabled):not(.disabled).active:focus,
.show > .panel-default .panel-heading .options .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 194, 0.5);
}

.panel-default .panel-body {
  background: #fff;
  color: #333333;
}

/* Overstated Panels */
.panel-overstated {
  border-color: #ddd;
}

.panel-overstated > .panel-heading {
  color: #fff;
  background-color: #046B99;
  border-color: #034867;
}

.panel-overstated > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-overstated > .panel-heading .badge {
  color: #046B99;
  background-color: #fff;
}

.panel-overstated > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-overstated .panel-heading {
  border-bottom-width: 5px;
  border-bottom-style: solid;
}

.panel-overstated .panel-heading h2 span[class^="ca-gov-icon-"], .panel-overstated .panel-heading h3 span[class^="ca-gov-icon-"], .panel-overstated .panel-heading h4 span[class^="ca-gov-icon-"], .panel-overstated .panel-heading h5 span[class^="ca-gov-icon-"] {
  color: #fff;
}

.panel-overstated .panel-heading .options .btn-default {
  color: #fff;
  background-color: #034f71;
  border-color: #034867;
}

.panel-overstated .panel-heading .options .btn-default:hover {
  color: #fff;
  background-color: #02354c;
  border-color: #012536;
}

.panel-overstated .panel-heading .options .btn-default:focus, .panel-overstated .panel-heading .options .btn-default.focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 100, 126, 0.5);
}

.panel-overstated .panel-heading .options .btn-default.disabled, .panel-overstated .panel-heading .options .btn-default:disabled {
  color: #fff;
  background-color: #034f71;
  border-color: #034867;
}

.panel-overstated .panel-heading .options .btn-default:not(:disabled):not(.disabled):active, .panel-overstated .panel-heading .options .btn-default:not(:disabled):not(.disabled).active,
.show > .panel-overstated .panel-heading .options .btn-default.dropdown-toggle {
  color: #fff;
  background-color: #022c40;
  border-color: #011d29;
}

.panel-overstated .panel-heading .options .btn-default:not(:disabled):not(.disabled):active:focus, .panel-overstated .panel-heading .options .btn-default:not(:disabled):not(.disabled).active:focus,
.show > .panel-overstated .panel-heading .options .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(41, 100, 126, 0.5);
}

.panel-overstated .panel-body {
  background: #fff;
  color: #333333;
}

.panel-overstated .panel-body a:not(.btn) {
  color: #046B99;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.panel-overstated .panel-body a:not(.btn):hover, .panel-overstated .panel-body a:not(.btn):focus {
  color: #02374e;
  text-decoration: underline;
}

.panel-overstated .panel-body a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.panel-overstated .panel-body a:not(.btn) {
  background-image: linear-gradient(to right, #046B99 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.panel-overstated .panel-body a:not(.btn):hover {
  background-image: linear-gradient(to right, #02374e 50%, transparent 50%);
  text-decoration: none !important;
}

/* Uderstated Panels */
.panel-understated {
  border-color: #ddd;
}

.panel-understated > .panel-heading {
  color: #333333;
  background-color: #E1F2F7;
  border-color: #ddd;
}

.panel-understated > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-understated > .panel-heading .badge {
  color: #E1F2F7;
  background-color: #333333;
}

.panel-understated > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-understated .panel-heading {
  border-bottom-width: 2px;
  border-bottom-style: dotted;
}

.panel-understated .panel-heading h2 span[class^="ca-gov-icon-"], .panel-understated .panel-heading h3 span[class^="ca-gov-icon-"], .panel-understated .panel-heading h4 span[class^="ca-gov-icon-"], .panel-understated .panel-heading h5 span[class^="ca-gov-icon-"] {
  color: #323A45;
}

.panel-understated .panel-heading .options .btn-default {
  color: #212529;
  background-color: #c1e4ee;
  border-color: #ddd;
}

.panel-understated .panel-heading .options .btn-default:hover {
  color: #212529;
  background-color: #a3d7e6;
  border-color: #c4c4c4;
}

.panel-understated .panel-heading .options .btn-default:focus, .panel-understated .panel-heading .options .btn-default.focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 194, 0.5);
}

.panel-understated .panel-heading .options .btn-default.disabled, .panel-understated .panel-heading .options .btn-default:disabled {
  color: #212529;
  background-color: #c1e4ee;
  border-color: #ddd;
}

.panel-understated .panel-heading .options .btn-default:not(:disabled):not(.disabled):active, .panel-understated .panel-heading .options .btn-default:not(:disabled):not(.disabled).active,
.show > .panel-understated .panel-heading .options .btn-default.dropdown-toggle {
  color: #212529;
  background-color: #99d3e4;
  border-color: #bdbdbd;
}

.panel-understated .panel-heading .options .btn-default:not(:disabled):not(.disabled):active:focus, .panel-understated .panel-heading .options .btn-default:not(:disabled):not(.disabled).active:focus,
.show > .panel-understated .panel-heading .options .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(193, 193, 194, 0.5);
}

.panel-understated .panel-body {
  background: #fff;
  color: #333333;
}

/* Standout Panels */
.panel-standout {
  border-color: #ddd;
}

.panel-standout > .panel-heading {
  color: #fff;
  background-color: #323A45;
  border-color: #FDB81E;
}

.panel-standout > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #ddd;
}

.panel-standout > .panel-heading .badge {
  color: #323A45;
  background-color: #fff;
}

.panel-standout > .panel-footer + .panel-collapse > .panel-body {
  border-bottom-color: #ddd;
}

.panel-standout .panel-heading {
  border-bottom-width: 5px;
  border-bottom-style: solid;
}

.panel-standout .panel-heading h2 span[class^="ca-gov-icon-"], .panel-standout .panel-heading h3 span[class^="ca-gov-icon-"], .panel-standout .panel-heading h4 span[class^="ca-gov-icon-"], .panel-standout .panel-heading h5 span[class^="ca-gov-icon-"] {
  color: #FDB81E;
}

.panel-standout .panel-heading .options .btn-default {
  color: #fff;
  background-color: #21262d;
  border-color: #272e36;
}

.panel-standout .panel-heading .options .btn-default:hover {
  color: #fff;
  background-color: #111317;
  border-color: #121519;
}

.panel-standout .panel-heading .options .btn-default:focus, .panel-standout .panel-heading .options .btn-default.focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 77, 84, 0.5);
}

.panel-standout .panel-heading .options .btn-default.disabled, .panel-standout .panel-heading .options .btn-default:disabled {
  color: #fff;
  background-color: #21262d;
  border-color: #272e36;
}

.panel-standout .panel-heading .options .btn-default:not(:disabled):not(.disabled):active, .panel-standout .panel-heading .options .btn-default:not(:disabled):not(.disabled).active,
.show > .panel-standout .panel-heading .options .btn-default.dropdown-toggle {
  color: #fff;
  background-color: #0b0d10;
  border-color: #0d0f11;
}

.panel-standout .panel-heading .options .btn-default:not(:disabled):not(.disabled):active:focus, .panel-standout .panel-heading .options .btn-default:not(:disabled):not(.disabled).active:focus,
.show > .panel-standout .panel-heading .options .btn-default.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 77, 84, 0.5);
}

.panel-standout .panel-body {
  background: #fff;
  color: #333333;
}

.panel-standout .panel-body a:not(.btn) {
  color: #046B99;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.panel-standout .panel-body a:not(.btn):hover, .panel-standout .panel-body a:not(.btn):focus {
  color: #02374e;
  text-decoration: underline;
}

.panel-standout .panel-body a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.panel-standout .panel-body a:not(.btn) {
  background-image: linear-gradient(to right, #046B99 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.panel-standout .panel-body a:not(.btn):hover {
  background-image: linear-gradient(to right, #02374e 50%, transparent 50%);
  text-decoration: none !important;
}

/* Adds triangle to panel */
.panel-standout.highlight .panel-heading .triangle {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid #FDB81E;
}

/* -----------------------------------------
   TABS
   /source/scss/colorscheme/cs-tabs.scss
----------------------------------------- */
.tab-group {
  background: #fff;
}

.tab-group .nav-tabs {
  background: #f5f5f5;
}

/* -----------------------------------------
   MEDIA
   /source/scss/colorscheme/media.scss
----------------------------------------- */
figure {
  background-color: #E1F2F7;
  border-color: #9FDBF1;
}

/* -----------------------------------------
   PANELS
   /source/scss/colorscheme/location.scss
----------------------------------------- */
.location.mini [class^="ca-gov-icon"] {
  background: #323A45;
  color: #ffffff;
}

/* -----------------------------------------
   TYPOGRAPHY
   /source/scss/colorscheme/typography.scss
----------------------------------------- */
.published {
  color: #555555;
}

/* -----------------------------------------
   SECTIONS
   /source/scss/colorscheme/sections.scss
----------------------------------------- */
.section-default, .card-default {
  background: whitesmoke;
}

.section-search, .card-search {
  background: #d6d6d6;
}

.section-understated, .card-understated {
  background: #E1F2F7;
}

.section-overstated, .card-overstated {
  background: #046B99;
  color: #ffffff;
}

.section-overstated a:not(.btn), .card-overstated a:not(.btn) {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.section-overstated a:not(.btn):hover, .section-overstated a:not(.btn):focus, .card-overstated a:not(.btn):hover, .card-overstated a:not(.btn):focus {
  color: #E1F2F7;
  text-decoration: underline;
}

.section-overstated a:not(.btn):focus, .card-overstated a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.section-overstated a:not(.btn), .card-overstated a:not(.btn) {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.section-overstated a:not(.btn):hover, .card-overstated a:not(.btn):hover {
  background-image: linear-gradient(to right, #E1F2F7 50%, transparent 50%);
  text-decoration: none !important;
}

.section-overstated .btn-primary, .card-overstated .btn-primary {
  color: #fff;
  background-color: #046B99;
  border-color: #ffffff;
}

.section-overstated .btn-primary:hover, .section-overstated .btn-primary:focus, .section-overstated .btn-primary:not(:disabled):not(.disabled):active, .section-overstated .btn-primary:not(:disabled):not(.disabled).active, .section-overstated .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .section-overstated .btn-primary, .card-overstated .btn-primary:hover, .card-overstated .btn-primary:focus, .card-overstated .btn-primary:not(:disabled):not(.disabled):active, .card-overstated .btn-primary:not(:disabled):not(.disabled).active, .card-overstated .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .card-overstated .btn-primary {
  color: #fff;
  background-color: #035376;
  border-color: #ffffff;
}

.section-overstated .btn-primary:not(:disabled):not(.disabled):active, .section-overstated .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-overstated .btn-primary, .card-overstated .btn-primary:not(:disabled):not(.disabled):active, .card-overstated .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-overstated .btn-primary {
  background-image: none;
}

.section-overstated .btn-primary.disabled, .section-overstated .btn-primary.disabled:hover, .section-overstated .btn-primary.disabled:focus, .section-overstated .btn-primary.disabled:active, .section-overstated .btn-primary.disabled.active, .section-overstated .btn-primary[disabled], .section-overstated .btn-primary[disabled]:hover, .section-overstated .btn-primary[disabled]:focus, .section-overstated .btn-primary[disabled]:active, .section-overstated .btn-primary[disabled].active, fieldset[disabled] .section-overstated .btn-primary, fieldset[disabled] .section-overstated .btn-primary:hover, fieldset[disabled] .section-overstated .btn-primary:focus, fieldset[disabled] .section-overstated .btn-primary:active, fieldset[disabled] .section-overstated .btn-primary.active, .card-overstated .btn-primary.disabled, .card-overstated .btn-primary.disabled:hover, .card-overstated .btn-primary.disabled:focus, .card-overstated .btn-primary.disabled:active, .card-overstated .btn-primary.disabled.active, .card-overstated .btn-primary[disabled], .card-overstated .btn-primary[disabled]:hover, .card-overstated .btn-primary[disabled]:focus, .card-overstated .btn-primary[disabled]:active, .card-overstated .btn-primary[disabled].active, fieldset[disabled] .card-overstated .btn-primary, fieldset[disabled] .card-overstated .btn-primary:hover, fieldset[disabled] .card-overstated .btn-primary:focus, fieldset[disabled] .card-overstated .btn-primary:active, fieldset[disabled] .card-overstated .btn-primary.active {
  background-color: #046B99 !important;
  border-color: #ffffff;
}

.section-overstated .btn-primary .badge, .card-overstated .btn-primary .badge {
  color: #046B99;
  background-color: #fff;
}

.section-overstated .btn-standout, .card-overstated .btn-standout {
  color: #fff;
  background-color: #323A45;
  border-color: #ffffff;
}

.section-overstated .btn-standout:hover, .section-overstated .btn-standout:focus, .section-overstated .btn-standout:not(:disabled):not(.disabled):active, .section-overstated .btn-standout:not(:disabled):not(.disabled).active, .section-overstated .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .section-overstated .btn-standout, .card-overstated .btn-standout:hover, .card-overstated .btn-standout:focus, .card-overstated .btn-standout:not(:disabled):not(.disabled):active, .card-overstated .btn-standout:not(:disabled):not(.disabled).active, .card-overstated .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .card-overstated .btn-standout {
  color: #fff;
  background-color: #232930;
  border-color: #ffffff;
}

.section-overstated .btn-standout:not(:disabled):not(.disabled):active, .section-overstated .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-overstated .btn-standout, .card-overstated .btn-standout:not(:disabled):not(.disabled):active, .card-overstated .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-overstated .btn-standout {
  background-image: none;
}

.section-overstated .btn-standout.disabled, .section-overstated .btn-standout.disabled:hover, .section-overstated .btn-standout.disabled:focus, .section-overstated .btn-standout.disabled:active, .section-overstated .btn-standout.disabled.active, .section-overstated .btn-standout[disabled], .section-overstated .btn-standout[disabled]:hover, .section-overstated .btn-standout[disabled]:focus, .section-overstated .btn-standout[disabled]:active, .section-overstated .btn-standout[disabled].active, fieldset[disabled] .section-overstated .btn-standout, fieldset[disabled] .section-overstated .btn-standout:hover, fieldset[disabled] .section-overstated .btn-standout:focus, fieldset[disabled] .section-overstated .btn-standout:active, fieldset[disabled] .section-overstated .btn-standout.active, .card-overstated .btn-standout.disabled, .card-overstated .btn-standout.disabled:hover, .card-overstated .btn-standout.disabled:focus, .card-overstated .btn-standout.disabled:active, .card-overstated .btn-standout.disabled.active, .card-overstated .btn-standout[disabled], .card-overstated .btn-standout[disabled]:hover, .card-overstated .btn-standout[disabled]:focus, .card-overstated .btn-standout[disabled]:active, .card-overstated .btn-standout[disabled].active, fieldset[disabled] .card-overstated .btn-standout, fieldset[disabled] .card-overstated .btn-standout:hover, fieldset[disabled] .card-overstated .btn-standout:focus, fieldset[disabled] .card-overstated .btn-standout:active, fieldset[disabled] .card-overstated .btn-standout.active {
  background-color: #323A45 !important;
  border-color: #ffffff;
}

.section-overstated .btn-standout .badge, .card-overstated .btn-standout .badge {
  color: #323A45;
  background-color: #fff;
}

.section-standout, .card-standout {
  background: #323A45;
  color: #ffffff;
}

.section-standout a:not(.btn), .card-standout a:not(.btn) {
  color: #50aee4;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.section-standout a:not(.btn):hover, .section-standout a:not(.btn):focus, .card-standout a:not(.btn):hover, .card-standout a:not(.btn):focus {
  color: #5cc2e7;
  text-decoration: underline;
}

.section-standout a:not(.btn):focus, .card-standout a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.section-standout a:not(.btn), .card-standout a:not(.btn) {
  background-image: linear-gradient(to right, #50aee4 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.section-standout a:not(.btn):hover, .card-standout a:not(.btn):hover {
  background-image: linear-gradient(to right, #5cc2e7 50%, transparent 50%);
  text-decoration: none !important;
}

.section-standout .half-gauge-chart .donut-background, .card-standout .half-gauge-chart .donut-background {
  fill: #d6d6d6;
}

.section-standout .half-gauge-chart .donut-halfmark, .card-standout .half-gauge-chart .donut-halfmark {
  stroke: whitesmoke;
}

.section-standout .btn-primary, .card-standout .btn-primary {
  color: #fff;
  background-color: #046B99;
  border-color: #ffffff;
}

.section-standout .btn-primary:hover, .section-standout .btn-primary:focus, .section-standout .btn-primary:not(:disabled):not(.disabled):active, .section-standout .btn-primary:not(:disabled):not(.disabled).active, .section-standout .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .section-standout .btn-primary, .card-standout .btn-primary:hover, .card-standout .btn-primary:focus, .card-standout .btn-primary:not(:disabled):not(.disabled):active, .card-standout .btn-primary:not(:disabled):not(.disabled).active, .card-standout .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .card-standout .btn-primary {
  color: #fff;
  background-color: #035376;
  border-color: #ffffff;
}

.section-standout .btn-primary:not(:disabled):not(.disabled):active, .section-standout .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-standout .btn-primary, .card-standout .btn-primary:not(:disabled):not(.disabled):active, .card-standout .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-standout .btn-primary {
  background-image: none;
}

.section-standout .btn-primary.disabled, .section-standout .btn-primary.disabled:hover, .section-standout .btn-primary.disabled:focus, .section-standout .btn-primary.disabled:active, .section-standout .btn-primary.disabled.active, .section-standout .btn-primary[disabled], .section-standout .btn-primary[disabled]:hover, .section-standout .btn-primary[disabled]:focus, .section-standout .btn-primary[disabled]:active, .section-standout .btn-primary[disabled].active, fieldset[disabled] .section-standout .btn-primary, fieldset[disabled] .section-standout .btn-primary:hover, fieldset[disabled] .section-standout .btn-primary:focus, fieldset[disabled] .section-standout .btn-primary:active, fieldset[disabled] .section-standout .btn-primary.active, .card-standout .btn-primary.disabled, .card-standout .btn-primary.disabled:hover, .card-standout .btn-primary.disabled:focus, .card-standout .btn-primary.disabled:active, .card-standout .btn-primary.disabled.active, .card-standout .btn-primary[disabled], .card-standout .btn-primary[disabled]:hover, .card-standout .btn-primary[disabled]:focus, .card-standout .btn-primary[disabled]:active, .card-standout .btn-primary[disabled].active, fieldset[disabled] .card-standout .btn-primary, fieldset[disabled] .card-standout .btn-primary:hover, fieldset[disabled] .card-standout .btn-primary:focus, fieldset[disabled] .card-standout .btn-primary:active, fieldset[disabled] .card-standout .btn-primary.active {
  background-color: #046B99 !important;
  border-color: #ffffff;
}

.section-standout .btn-primary .badge, .card-standout .btn-primary .badge {
  color: #046B99;
  background-color: #fff;
}

.section-standout .btn-standout, .card-standout .btn-standout {
  color: #fff;
  background-color: #323A45;
  border-color: #ffffff;
}

.section-standout .btn-standout:hover, .section-standout .btn-standout:focus, .section-standout .btn-standout:not(:disabled):not(.disabled):active, .section-standout .btn-standout:not(:disabled):not(.disabled).active, .section-standout .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .section-standout .btn-standout, .card-standout .btn-standout:hover, .card-standout .btn-standout:focus, .card-standout .btn-standout:not(:disabled):not(.disabled):active, .card-standout .btn-standout:not(:disabled):not(.disabled).active, .card-standout .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .card-standout .btn-standout {
  color: #fff;
  background-color: #232930;
  border-color: #ffffff;
}

.section-standout .btn-standout:not(:disabled):not(.disabled):active, .section-standout .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-standout .btn-standout, .card-standout .btn-standout:not(:disabled):not(.disabled):active, .card-standout .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-standout .btn-standout {
  background-image: none;
}

.section-standout .btn-standout.disabled, .section-standout .btn-standout.disabled:hover, .section-standout .btn-standout.disabled:focus, .section-standout .btn-standout.disabled:active, .section-standout .btn-standout.disabled.active, .section-standout .btn-standout[disabled], .section-standout .btn-standout[disabled]:hover, .section-standout .btn-standout[disabled]:focus, .section-standout .btn-standout[disabled]:active, .section-standout .btn-standout[disabled].active, fieldset[disabled] .section-standout .btn-standout, fieldset[disabled] .section-standout .btn-standout:hover, fieldset[disabled] .section-standout .btn-standout:focus, fieldset[disabled] .section-standout .btn-standout:active, fieldset[disabled] .section-standout .btn-standout.active, .card-standout .btn-standout.disabled, .card-standout .btn-standout.disabled:hover, .card-standout .btn-standout.disabled:focus, .card-standout .btn-standout.disabled:active, .card-standout .btn-standout.disabled.active, .card-standout .btn-standout[disabled], .card-standout .btn-standout[disabled]:hover, .card-standout .btn-standout[disabled]:focus, .card-standout .btn-standout[disabled]:active, .card-standout .btn-standout[disabled].active, fieldset[disabled] .card-standout .btn-standout, fieldset[disabled] .card-standout .btn-standout:hover, fieldset[disabled] .card-standout .btn-standout:focus, fieldset[disabled] .card-standout .btn-standout:active, fieldset[disabled] .card-standout .btn-standout.active {
  background-color: #323A45 !important;
  border-color: #ffffff;
}

.section-standout .btn-standout .badge, .card-standout .btn-standout .badge {
  color: #323A45;
  background-color: #fff;
}

.section-standout code, .card-standout code {
  color: #fff;
}

.section-impact, .card-impact {
  background: #323A45;
  color: #ffffff;
}

.section-impact a:not(.btn), .card-impact a:not(.btn) {
  color: #50aee4;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.section-impact a:not(.btn):hover, .section-impact a:not(.btn):focus, .card-impact a:not(.btn):hover, .card-impact a:not(.btn):focus {
  color: #5cc2e7;
  text-decoration: underline;
}

.section-impact a:not(.btn):focus, .card-impact a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.section-impact a:not(.btn), .card-impact a:not(.btn) {
  background-image: linear-gradient(to right, #50aee4 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.section-impact a:not(.btn):hover, .card-impact a:not(.btn):hover {
  background-image: linear-gradient(to right, #5cc2e7 50%, transparent 50%);
  text-decoration: none !important;
}

.section-impact .half-gauge-chart .donut-background, .card-impact .half-gauge-chart .donut-background {
  fill: #d6d6d6;
}

.section-impact .half-gauge-chart .donut-halfmark, .card-impact .half-gauge-chart .donut-halfmark {
  stroke: whitesmoke;
}

.section-impact .btn-primary, .card-impact .btn-primary {
  color: #fff;
  background-color: #046B99;
  border-color: #ffffff;
}

.section-impact .btn-primary:hover, .section-impact .btn-primary:focus, .section-impact .btn-primary:not(:disabled):not(.disabled):active, .section-impact .btn-primary:not(:disabled):not(.disabled).active, .section-impact .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .section-impact .btn-primary, .card-impact .btn-primary:hover, .card-impact .btn-primary:focus, .card-impact .btn-primary:not(:disabled):not(.disabled):active, .card-impact .btn-primary:not(:disabled):not(.disabled).active, .card-impact .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .card-impact .btn-primary {
  color: #fff;
  background-color: #035376;
  border-color: #ffffff;
}

.section-impact .btn-primary:not(:disabled):not(.disabled):active, .section-impact .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-impact .btn-primary, .card-impact .btn-primary:not(:disabled):not(.disabled):active, .card-impact .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-impact .btn-primary {
  background-image: none;
}

.section-impact .btn-primary.disabled, .section-impact .btn-primary.disabled:hover, .section-impact .btn-primary.disabled:focus, .section-impact .btn-primary.disabled:active, .section-impact .btn-primary.disabled.active, .section-impact .btn-primary[disabled], .section-impact .btn-primary[disabled]:hover, .section-impact .btn-primary[disabled]:focus, .section-impact .btn-primary[disabled]:active, .section-impact .btn-primary[disabled].active, fieldset[disabled] .section-impact .btn-primary, fieldset[disabled] .section-impact .btn-primary:hover, fieldset[disabled] .section-impact .btn-primary:focus, fieldset[disabled] .section-impact .btn-primary:active, fieldset[disabled] .section-impact .btn-primary.active, .card-impact .btn-primary.disabled, .card-impact .btn-primary.disabled:hover, .card-impact .btn-primary.disabled:focus, .card-impact .btn-primary.disabled:active, .card-impact .btn-primary.disabled.active, .card-impact .btn-primary[disabled], .card-impact .btn-primary[disabled]:hover, .card-impact .btn-primary[disabled]:focus, .card-impact .btn-primary[disabled]:active, .card-impact .btn-primary[disabled].active, fieldset[disabled] .card-impact .btn-primary, fieldset[disabled] .card-impact .btn-primary:hover, fieldset[disabled] .card-impact .btn-primary:focus, fieldset[disabled] .card-impact .btn-primary:active, fieldset[disabled] .card-impact .btn-primary.active {
  background-color: #046B99 !important;
  border-color: #ffffff;
}

.section-impact .btn-primary .badge, .card-impact .btn-primary .badge {
  color: #046B99;
  background-color: #fff;
}

.section-impact .btn-standout, .card-impact .btn-standout {
  color: #fff;
  background-color: #323A45;
  border-color: #ffffff;
}

.section-impact .btn-standout:hover, .section-impact .btn-standout:focus, .section-impact .btn-standout:not(:disabled):not(.disabled):active, .section-impact .btn-standout:not(:disabled):not(.disabled).active, .section-impact .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .section-impact .btn-standout, .card-impact .btn-standout:hover, .card-impact .btn-standout:focus, .card-impact .btn-standout:not(:disabled):not(.disabled):active, .card-impact .btn-standout:not(:disabled):not(.disabled).active, .card-impact .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .card-impact .btn-standout {
  color: #fff;
  background-color: #232930;
  border-color: #ffffff;
}

.section-impact .btn-standout:not(:disabled):not(.disabled):active, .section-impact .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-impact .btn-standout, .card-impact .btn-standout:not(:disabled):not(.disabled):active, .card-impact .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-impact .btn-standout {
  background-image: none;
}

.section-impact .btn-standout.disabled, .section-impact .btn-standout.disabled:hover, .section-impact .btn-standout.disabled:focus, .section-impact .btn-standout.disabled:active, .section-impact .btn-standout.disabled.active, .section-impact .btn-standout[disabled], .section-impact .btn-standout[disabled]:hover, .section-impact .btn-standout[disabled]:focus, .section-impact .btn-standout[disabled]:active, .section-impact .btn-standout[disabled].active, fieldset[disabled] .section-impact .btn-standout, fieldset[disabled] .section-impact .btn-standout:hover, fieldset[disabled] .section-impact .btn-standout:focus, fieldset[disabled] .section-impact .btn-standout:active, fieldset[disabled] .section-impact .btn-standout.active, .card-impact .btn-standout.disabled, .card-impact .btn-standout.disabled:hover, .card-impact .btn-standout.disabled:focus, .card-impact .btn-standout.disabled:active, .card-impact .btn-standout.disabled.active, .card-impact .btn-standout[disabled], .card-impact .btn-standout[disabled]:hover, .card-impact .btn-standout[disabled]:focus, .card-impact .btn-standout[disabled]:active, .card-impact .btn-standout[disabled].active, fieldset[disabled] .card-impact .btn-standout, fieldset[disabled] .card-impact .btn-standout:hover, fieldset[disabled] .card-impact .btn-standout:focus, fieldset[disabled] .card-impact .btn-standout:active, fieldset[disabled] .card-impact .btn-standout.active {
  background-color: #323A45 !important;
  border-color: #ffffff;
}

.section-impact .btn-standout .badge, .card-impact .btn-standout .badge {
  color: #323A45;
  background-color: #fff;
}

.section-impact .btn-default, .card-impact .btn-default {
  color: #fff;
  background-color: transparent;
  border-color: #9FDBF1;
  margin-bottom: 11.5px;
}

.section-impact .btn-default:hover, .section-impact .btn-default:focus, .section-impact .btn-default:not(:disabled):not(.disabled):active, .section-impact .btn-default:not(:disabled):not(.disabled).active, .section-impact .btn-default .show > .dropdown-toggle, .open > .dropdown-toggle .section-impact .btn-default, .card-impact .btn-default:hover, .card-impact .btn-default:focus, .card-impact .btn-default:not(:disabled):not(.disabled):active, .card-impact .btn-default:not(:disabled):not(.disabled).active, .card-impact .btn-default .show > .dropdown-toggle, .open > .dropdown-toggle .card-impact .btn-default {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #fff;
}

.section-impact .btn-default:not(:disabled):not(.disabled):active, .section-impact .btn-default:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-impact .btn-default, .card-impact .btn-default:not(:disabled):not(.disabled):active, .card-impact .btn-default:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-impact .btn-default {
  background-image: none;
}

.section-impact .btn-default.disabled, .section-impact .btn-default.disabled:hover, .section-impact .btn-default.disabled:focus, .section-impact .btn-default.disabled:active, .section-impact .btn-default.disabled.active, .section-impact .btn-default[disabled], .section-impact .btn-default[disabled]:hover, .section-impact .btn-default[disabled]:focus, .section-impact .btn-default[disabled]:active, .section-impact .btn-default[disabled].active, fieldset[disabled] .section-impact .btn-default, fieldset[disabled] .section-impact .btn-default:hover, fieldset[disabled] .section-impact .btn-default:focus, fieldset[disabled] .section-impact .btn-default:active, fieldset[disabled] .section-impact .btn-default.active, .card-impact .btn-default.disabled, .card-impact .btn-default.disabled:hover, .card-impact .btn-default.disabled:focus, .card-impact .btn-default.disabled:active, .card-impact .btn-default.disabled.active, .card-impact .btn-default[disabled], .card-impact .btn-default[disabled]:hover, .card-impact .btn-default[disabled]:focus, .card-impact .btn-default[disabled]:active, .card-impact .btn-default[disabled].active, fieldset[disabled] .card-impact .btn-default, fieldset[disabled] .card-impact .btn-default:hover, fieldset[disabled] .card-impact .btn-default:focus, fieldset[disabled] .card-impact .btn-default:active, fieldset[disabled] .card-impact .btn-default.active {
  background-color: transparent !important;
  border-color: #9FDBF1;
}

.section-impact .btn-default .badge, .card-impact .btn-default .badge {
  color: transparent;
  background-color: #fff;
}

.section-impact .btn-default [class^="ca-gov-icon-"], .card-impact .btn-default [class^="ca-gov-icon-"] {
  color: #9FDBF1;
  padding: 0 5px;
}

.section-primary, .card-primary {
  background: #046B99;
  color: #ffffff;
}

.section-primary a:not(.btn), .card-primary a:not(.btn) {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.section-primary a:not(.btn):hover, .section-primary a:not(.btn):focus, .card-primary a:not(.btn):hover, .card-primary a:not(.btn):focus {
  color: #E1F2F7;
  text-decoration: underline;
}

.section-primary a:not(.btn):focus, .card-primary a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.section-primary a:not(.btn), .card-primary a:not(.btn) {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.section-primary a:not(.btn):hover, .card-primary a:not(.btn):hover {
  background-image: linear-gradient(to right, #E1F2F7 50%, transparent 50%);
  text-decoration: none !important;
}

.section-primary .btn-primary, .card-primary .btn-primary {
  color: #fff;
  background-color: #046B99;
  border-color: #ffffff;
}

.section-primary .btn-primary:hover, .section-primary .btn-primary:focus, .section-primary .btn-primary:not(:disabled):not(.disabled):active, .section-primary .btn-primary:not(:disabled):not(.disabled).active, .section-primary .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .section-primary .btn-primary, .card-primary .btn-primary:hover, .card-primary .btn-primary:focus, .card-primary .btn-primary:not(:disabled):not(.disabled):active, .card-primary .btn-primary:not(:disabled):not(.disabled).active, .card-primary .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .card-primary .btn-primary {
  color: #fff;
  background-color: #035376;
  border-color: #ffffff;
}

.section-primary .btn-primary:not(:disabled):not(.disabled):active, .section-primary .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-primary .btn-primary, .card-primary .btn-primary:not(:disabled):not(.disabled):active, .card-primary .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-primary .btn-primary {
  background-image: none;
}

.section-primary .btn-primary.disabled, .section-primary .btn-primary.disabled:hover, .section-primary .btn-primary.disabled:focus, .section-primary .btn-primary.disabled:active, .section-primary .btn-primary.disabled.active, .section-primary .btn-primary[disabled], .section-primary .btn-primary[disabled]:hover, .section-primary .btn-primary[disabled]:focus, .section-primary .btn-primary[disabled]:active, .section-primary .btn-primary[disabled].active, fieldset[disabled] .section-primary .btn-primary, fieldset[disabled] .section-primary .btn-primary:hover, fieldset[disabled] .section-primary .btn-primary:focus, fieldset[disabled] .section-primary .btn-primary:active, fieldset[disabled] .section-primary .btn-primary.active, .card-primary .btn-primary.disabled, .card-primary .btn-primary.disabled:hover, .card-primary .btn-primary.disabled:focus, .card-primary .btn-primary.disabled:active, .card-primary .btn-primary.disabled.active, .card-primary .btn-primary[disabled], .card-primary .btn-primary[disabled]:hover, .card-primary .btn-primary[disabled]:focus, .card-primary .btn-primary[disabled]:active, .card-primary .btn-primary[disabled].active, fieldset[disabled] .card-primary .btn-primary, fieldset[disabled] .card-primary .btn-primary:hover, fieldset[disabled] .card-primary .btn-primary:focus, fieldset[disabled] .card-primary .btn-primary:active, fieldset[disabled] .card-primary .btn-primary.active {
  background-color: #046B99 !important;
  border-color: #ffffff;
}

.section-primary .btn-primary .badge, .card-primary .btn-primary .badge {
  color: #046B99;
  background-color: #fff;
}

.section-primary .btn-standout, .card-primary .btn-standout {
  color: #fff;
  background-color: #323A45;
  border-color: #ffffff;
}

.section-primary .btn-standout:hover, .section-primary .btn-standout:focus, .section-primary .btn-standout:not(:disabled):not(.disabled):active, .section-primary .btn-standout:not(:disabled):not(.disabled).active, .section-primary .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .section-primary .btn-standout, .card-primary .btn-standout:hover, .card-primary .btn-standout:focus, .card-primary .btn-standout:not(:disabled):not(.disabled):active, .card-primary .btn-standout:not(:disabled):not(.disabled).active, .card-primary .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .card-primary .btn-standout {
  color: #fff;
  background-color: #232930;
  border-color: #ffffff;
}

.section-primary .btn-standout:not(:disabled):not(.disabled):active, .section-primary .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-primary .btn-standout, .card-primary .btn-standout:not(:disabled):not(.disabled):active, .card-primary .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-primary .btn-standout {
  background-image: none;
}

.section-primary .btn-standout.disabled, .section-primary .btn-standout.disabled:hover, .section-primary .btn-standout.disabled:focus, .section-primary .btn-standout.disabled:active, .section-primary .btn-standout.disabled.active, .section-primary .btn-standout[disabled], .section-primary .btn-standout[disabled]:hover, .section-primary .btn-standout[disabled]:focus, .section-primary .btn-standout[disabled]:active, .section-primary .btn-standout[disabled].active, fieldset[disabled] .section-primary .btn-standout, fieldset[disabled] .section-primary .btn-standout:hover, fieldset[disabled] .section-primary .btn-standout:focus, fieldset[disabled] .section-primary .btn-standout:active, fieldset[disabled] .section-primary .btn-standout.active, .card-primary .btn-standout.disabled, .card-primary .btn-standout.disabled:hover, .card-primary .btn-standout.disabled:focus, .card-primary .btn-standout.disabled:active, .card-primary .btn-standout.disabled.active, .card-primary .btn-standout[disabled], .card-primary .btn-standout[disabled]:hover, .card-primary .btn-standout[disabled]:focus, .card-primary .btn-standout[disabled]:active, .card-primary .btn-standout[disabled].active, fieldset[disabled] .card-primary .btn-standout, fieldset[disabled] .card-primary .btn-standout:hover, fieldset[disabled] .card-primary .btn-standout:focus, fieldset[disabled] .card-primary .btn-standout:active, fieldset[disabled] .card-primary .btn-standout.active {
  background-color: #323A45 !important;
  border-color: #ffffff;
}

.section-primary .btn-standout .badge, .card-primary .btn-standout .badge {
  color: #323A45;
  background-color: #fff;
}

.section-inverted, .card-inverted {
  background: #222222;
  color: #fff;
}

.section-inverted a:not(.btn), .card-inverted a:not(.btn) {
  color: #50aee4;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.section-inverted a:not(.btn):hover, .section-inverted a:not(.btn):focus, .card-inverted a:not(.btn):hover, .card-inverted a:not(.btn):focus {
  color: #31a0df;
  text-decoration: underline;
}

.section-inverted a:not(.btn):focus, .card-inverted a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.section-inverted a:not(.btn), .card-inverted a:not(.btn) {
  background-image: linear-gradient(to right, #50aee4 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.section-inverted a:not(.btn):hover, .card-inverted a:not(.btn):hover {
  background-image: linear-gradient(to right, #31a0df 50%, transparent 50%);
  text-decoration: none !important;
}

.section-inverted .btn-primary, .card-inverted .btn-primary {
  color: #fff;
  background-color: #046B99;
  border-color: #ffffff;
}

.section-inverted .btn-primary:hover, .section-inverted .btn-primary:focus, .section-inverted .btn-primary:not(:disabled):not(.disabled):active, .section-inverted .btn-primary:not(:disabled):not(.disabled).active, .section-inverted .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .section-inverted .btn-primary, .card-inverted .btn-primary:hover, .card-inverted .btn-primary:focus, .card-inverted .btn-primary:not(:disabled):not(.disabled):active, .card-inverted .btn-primary:not(:disabled):not(.disabled).active, .card-inverted .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .card-inverted .btn-primary {
  color: #fff;
  background-color: #035376;
  border-color: #ffffff;
}

.section-inverted .btn-primary:not(:disabled):not(.disabled):active, .section-inverted .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-inverted .btn-primary, .card-inverted .btn-primary:not(:disabled):not(.disabled):active, .card-inverted .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-inverted .btn-primary {
  background-image: none;
}

.section-inverted .btn-primary.disabled, .section-inverted .btn-primary.disabled:hover, .section-inverted .btn-primary.disabled:focus, .section-inverted .btn-primary.disabled:active, .section-inverted .btn-primary.disabled.active, .section-inverted .btn-primary[disabled], .section-inverted .btn-primary[disabled]:hover, .section-inverted .btn-primary[disabled]:focus, .section-inverted .btn-primary[disabled]:active, .section-inverted .btn-primary[disabled].active, fieldset[disabled] .section-inverted .btn-primary, fieldset[disabled] .section-inverted .btn-primary:hover, fieldset[disabled] .section-inverted .btn-primary:focus, fieldset[disabled] .section-inverted .btn-primary:active, fieldset[disabled] .section-inverted .btn-primary.active, .card-inverted .btn-primary.disabled, .card-inverted .btn-primary.disabled:hover, .card-inverted .btn-primary.disabled:focus, .card-inverted .btn-primary.disabled:active, .card-inverted .btn-primary.disabled.active, .card-inverted .btn-primary[disabled], .card-inverted .btn-primary[disabled]:hover, .card-inverted .btn-primary[disabled]:focus, .card-inverted .btn-primary[disabled]:active, .card-inverted .btn-primary[disabled].active, fieldset[disabled] .card-inverted .btn-primary, fieldset[disabled] .card-inverted .btn-primary:hover, fieldset[disabled] .card-inverted .btn-primary:focus, fieldset[disabled] .card-inverted .btn-primary:active, fieldset[disabled] .card-inverted .btn-primary.active {
  background-color: #046B99 !important;
  border-color: #ffffff;
}

.section-inverted .btn-primary .badge, .card-inverted .btn-primary .badge {
  color: #046B99;
  background-color: #fff;
}

.section-inverted .btn-standout, .card-inverted .btn-standout {
  color: #fff;
  background-color: #323A45;
  border-color: #ffffff;
}

.section-inverted .btn-standout:hover, .section-inverted .btn-standout:focus, .section-inverted .btn-standout:not(:disabled):not(.disabled):active, .section-inverted .btn-standout:not(:disabled):not(.disabled).active, .section-inverted .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .section-inverted .btn-standout, .card-inverted .btn-standout:hover, .card-inverted .btn-standout:focus, .card-inverted .btn-standout:not(:disabled):not(.disabled):active, .card-inverted .btn-standout:not(:disabled):not(.disabled).active, .card-inverted .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .card-inverted .btn-standout {
  color: #fff;
  background-color: #232930;
  border-color: #ffffff;
}

.section-inverted .btn-standout:not(:disabled):not(.disabled):active, .section-inverted .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .section-inverted .btn-standout, .card-inverted .btn-standout:not(:disabled):not(.disabled):active, .card-inverted .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .card-inverted .btn-standout {
  background-image: none;
}

.section-inverted .btn-standout.disabled, .section-inverted .btn-standout.disabled:hover, .section-inverted .btn-standout.disabled:focus, .section-inverted .btn-standout.disabled:active, .section-inverted .btn-standout.disabled.active, .section-inverted .btn-standout[disabled], .section-inverted .btn-standout[disabled]:hover, .section-inverted .btn-standout[disabled]:focus, .section-inverted .btn-standout[disabled]:active, .section-inverted .btn-standout[disabled].active, fieldset[disabled] .section-inverted .btn-standout, fieldset[disabled] .section-inverted .btn-standout:hover, fieldset[disabled] .section-inverted .btn-standout:focus, fieldset[disabled] .section-inverted .btn-standout:active, fieldset[disabled] .section-inverted .btn-standout.active, .card-inverted .btn-standout.disabled, .card-inverted .btn-standout.disabled:hover, .card-inverted .btn-standout.disabled:focus, .card-inverted .btn-standout.disabled:active, .card-inverted .btn-standout.disabled.active, .card-inverted .btn-standout[disabled], .card-inverted .btn-standout[disabled]:hover, .card-inverted .btn-standout[disabled]:focus, .card-inverted .btn-standout[disabled]:active, .card-inverted .btn-standout[disabled].active, fieldset[disabled] .card-inverted .btn-standout, fieldset[disabled] .card-inverted .btn-standout:hover, fieldset[disabled] .card-inverted .btn-standout:focus, fieldset[disabled] .card-inverted .btn-standout:active, fieldset[disabled] .card-inverted .btn-standout.active {
  background-color: #323A45 !important;
  border-color: #ffffff;
}

.section-inverted .btn-standout .badge, .card-inverted .btn-standout .badge {
  color: #323A45;
  background-color: #fff;
}

.section-danger, .card-danger {
  background: #D24532;
  color: #000000;
}

.section-danger a:not(.btn), .card-danger a:not(.btn) {
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.section-danger a:not(.btn):hover, .section-danger a:not(.btn):focus, .card-danger a:not(.btn):hover, .card-danger a:not(.btn):focus {
  color: #000;
  text-decoration: underline;
}

.section-danger a:not(.btn):focus, .card-danger a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.section-danger a:not(.btn), .card-danger a:not(.btn) {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.section-danger a:not(.btn):hover, .card-danger a:not(.btn):hover {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  text-decoration: none !important;
}

.bg-highlight {
  color: #000;
}

.bg-highlight a:not(.btn) {
  color: #333333;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.bg-highlight a:not(.btn):hover, .bg-highlight a:not(.btn):focus {
  color: #000;
  text-decoration: underline;
}

.bg-highlight a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.bg-highlight a:not(.btn) {
  background-image: linear-gradient(to right, #333333 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.bg-highlight a:not(.btn):hover {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  text-decoration: none !important;
}

.bg-primary {
  color: #fff;
}

.bg-primary a:not(.btn) {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.bg-primary a:not(.btn):hover, .bg-primary a:not(.btn):focus {
  color: #E1F2F7;
  text-decoration: underline;
}

.bg-primary a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.bg-primary a:not(.btn) {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.bg-primary a:not(.btn):hover {
  background-image: linear-gradient(to right, #E1F2F7 50%, transparent 50%);
  text-decoration: none !important;
}

.bg-standout {
  color: #fff;
}

.bg-standout a:not(.btn) {
  color: #50aee4;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.bg-standout a:not(.btn):hover, .bg-standout a:not(.btn):focus {
  color: #5cc2e7;
  text-decoration: underline;
}

.bg-standout a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.bg-standout a:not(.btn) {
  background-image: linear-gradient(to right, #50aee4 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.bg-standout a:not(.btn):hover {
  background-image: linear-gradient(to right, #5cc2e7 50%, transparent 50%);
  text-decoration: none !important;
}

a.text-white:not(.btn) {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

a.text-white:not(.btn):hover {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

/* -----------------------------------------
   HEADER SLIDESHOW BANNER
   /source/scss/colorscheme/cs-header-slideshow-banner.scss
----------------------------------------- */
/* HEADER SLIDESHOW */
.header-slideshow-banner {
  background: #000;
}

/* -----------------------------------------
   PROGRESS BARS
   /source/scss/cagov/cs-progress-bar.scss
----------------------------------------- */
/* Traditional Colors */
.progress-bar-default {
  background-color: #d6d6d6;
}

.progress-striped .progress-bar-default {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-primary {
  background-color: #046B99;
}

.progress-striped .progress-bar-primary {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-understated {
  background-color: #E1F2F7;
}

.progress-striped .progress-bar-understated {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-overstated {
  background-color: #50aee4;
}

.progress-striped .progress-bar-overstated {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-standout {
  background-color: #323A45;
}

.progress-striped .progress-bar-standout {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

/* Accent Colors */
.progress-bar-accent1 {
  background-color: #1B79C0;
}

.progress-striped .progress-bar-accent1 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent1 {
  background-color: #1B79C0;
}

.progress-striped .progress-bar-accent1 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent2 {
  background-color: #077CBB;
}

.progress-striped .progress-bar-accent2 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent3 {
  background-color: #258378;
}

.progress-striped .progress-bar-accent3 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent4 {
  background-color: #32852E;
}

.progress-striped .progress-bar-accent4 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent5 {
  background-color: #815ab4;
}

.progress-striped .progress-bar-accent5 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent6 {
  background-color: #BE37CD;
}

.progress-striped .progress-bar-accent6 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent7 {
  background-color: #C93E2C;
}

.progress-striped .progress-bar-accent7 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent8 {
  background-color: #BB520C;
}

.progress-striped .progress-bar-accent8 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.progress-bar-accent9 {
  background-color: #f7e455;
}

.progress-striped .progress-bar-accent9 {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

/* -----------------------------------------
   COMMENT
   /source/scss/colorscheme/cs-testimonials.scss
----------------------------------------- */
.testimonial-default {
  background: whitesmoke;
}

.testimonial-understated {
  background: #E1F2F7;
}

.testimonial-overstated {
  background: #50aee4;
  color: #333333;
}

.testimonial-overstated a:not(.btn) {
  color: #333333;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.testimonial-overstated a:not(.btn):hover, .testimonial-overstated a:not(.btn):focus {
  color: #000;
  text-decoration: underline;
}

.testimonial-overstated a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.testimonial-overstated a:not(.btn) {
  background-image: linear-gradient(to right, #333333 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.testimonial-overstated a:not(.btn):hover {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  text-decoration: none !important;
}

.testimonial-overstated footer {
  color: #000000;
}

.testimonial-standout {
  background: #323A45;
  color: #ffffff;
}

.testimonial-standout a:not(.btn) {
  color: #FDB81E;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.testimonial-standout a:not(.btn):hover, .testimonial-standout a:not(.btn):focus {
  color: #fff;
  text-decoration: underline;
}

.testimonial-standout a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.testimonial-standout a:not(.btn) {
  background-image: linear-gradient(to right, #FDB81E 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.testimonial-standout a:not(.btn):hover {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  text-decoration: none !important;
}

.testimonial-standout footer {
  color: #ffffff;
}

.testimonial-primary {
  background: #046B99;
  color: #ffffff;
}

.testimonial-primary a:not(.btn) {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.testimonial-primary a:not(.btn):hover, .testimonial-primary a:not(.btn):focus {
  color: #E1F2F7;
  text-decoration: underline;
}

.testimonial-primary a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.testimonial-primary a:not(.btn) {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.testimonial-primary a:not(.btn):hover {
  background-image: linear-gradient(to right, #E1F2F7 50%, transparent 50%);
  text-decoration: none !important;
}

.testimonial-primary footer {
  color: #ffffff;
}

.testimonial-highlight {
  background: #FDB81E;
  color: #333333;
}

.testimonial-highlight a:not(.btn) {
  color: #333333;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.testimonial-highlight a:not(.btn):hover, .testimonial-highlight a:not(.btn):focus {
  color: #000;
  text-decoration: underline;
}

.testimonial-highlight a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.testimonial-highlight a:not(.btn) {
  background-image: linear-gradient(to right, #333333 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.testimonial-highlight a:not(.btn):hover {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  text-decoration: none !important;
}

.testimonial-highlight footer {
  color: #000000;
}

.testimonial-inverted {
  background: #222222;
  color: #fff;
}

.testimonial-inverted a:not(.btn) {
  color: #FDB81E;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.testimonial-inverted a:not(.btn):hover, .testimonial-inverted a:not(.btn):focus {
  color: #fff;
  text-decoration: underline;
}

.testimonial-inverted a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.testimonial-inverted a:not(.btn) {
  background-image: linear-gradient(to right, #FDB81E 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.testimonial-inverted a:not(.btn):hover {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  text-decoration: none !important;
}

.testimonial-inverted footer {
  color: #ffffff;
}

.testimonial-danger {
  background: #D24532;
  color: #000000;
}

.testimonial-danger a:not(.btn) {
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.testimonial-danger a:not(.btn):hover, .testimonial-danger a:not(.btn):focus {
  color: #000;
  text-decoration: underline;
}

.testimonial-danger a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.testimonial-danger a:not(.btn) {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.testimonial-danger a:not(.btn):hover {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  text-decoration: none !important;
}

.testimonial-danger footer {
  color: #000000;
}

/* -----------------------------------------
   CARDS
   /source/scss/colorscheme/cs-cards.scss
----------------------------------------- */
.card-default {
  background-color: white;
  border-color: #d6d6d6;
  color: #000000;
}

.card-default .card-header {
  border-bottom: 1px solid #d6d6d6;
  background-color: #f2f2f2;
}

.card-default .card-footer {
  border-top: 1px solid #d6d6d6;
  background-color: #f2f2f2;
}

.card-primary {
  background-color: #046B99;
  border-color: #046B99;
  color: #ffffff;
}

.card-primary a:not(.btn) {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.card-primary a:not(.btn):hover, .card-primary a:not(.btn):focus {
  color: #E1F2F7;
  text-decoration: underline;
}

.card-primary a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.card-primary a:not(.btn) {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.card-primary a:not(.btn):hover {
  background-image: linear-gradient(to right, #E1F2F7 50%, transparent 50%);
  text-decoration: none !important;
}

.card-primary .card-header {
  border-bottom: 1px solid #046B99;
  background-color: #035a80;
}

.card-primary .card-footer {
  border-top: 1px solid #046B99;
  background-color: #035a80;
}

.card-understated {
  background-color: #E1F2F7;
  border-color: #cde9f2;
  color: #000000;
}

.card-understated .card-header {
  border-bottom: 1px solid #cde9f2;
  background-color: #cde9f2;
}

.card-understated .card-footer {
  border-top: 1px solid #cde9f2;
  background-color: #cde9f2;
}

.card-overstated {
  background-color: #50aee4;
  border-color: #2d9edf;
  color: #000000;
}

.card-overstated a:not(.btn) {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.card-overstated a:not(.btn):hover, .card-overstated a:not(.btn):focus {
  color: #FDB81E;
  text-decoration: underline;
}

.card-overstated a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.card-overstated .card-header {
  border-bottom: 1px solid #2d9edf;
  background-color: #3aa4e1;
}

.card-overstated .card-footer {
  border-top: 1px solid #2d9edf;
  background-color: #3aa4e1;
}

.card-standout {
  background-color: #323A45;
  border-color: #21262d;
  color: #ffffff;
}

.card-standout a:not(.btn) {
  color: whitesmoke;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.card-standout a:not(.btn):hover, .card-standout a:not(.btn):focus {
  color: #FDB81E;
  text-decoration: underline;
}

.card-standout a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.card-standout .card-header {
  border-bottom: 1px solid #21262d;
  background-color: #272e36;
}

.card-standout .card-footer {
  border-top: 1px solid #21262d;
  background-color: #272e36;
}

.card-inverted, .card-inverse {
  background-color: #000000;
  border-color: #000000;
  color: #ffffff;
}

.card-inverted a:not(.btn), .card-inverse a:not(.btn) {
  color: #50aee4;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.card-inverted a:not(.btn):hover, .card-inverted a:not(.btn):focus, .card-inverse a:not(.btn):hover, .card-inverse a:not(.btn):focus {
  color: #31a0df;
  text-decoration: underline;
}

.card-inverted a:not(.btn):focus, .card-inverse a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.card-inverted a:not(.btn), .card-inverse a:not(.btn) {
  background-image: linear-gradient(to right, #50aee4 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.card-inverted a:not(.btn):hover, .card-inverse a:not(.btn):hover {
  background-image: linear-gradient(to right, #31a0df 50%, transparent 50%);
  text-decoration: none !important;
}

.card-inverted .card-header, .card-inverse .card-header {
  border-bottom: 1px solid #000000;
  background-color: black;
}

.card-inverted .card-footer, .card-inverse .card-footer {
  border-top: 1px solid #000000;
  background-color: black;
}

.card-success {
  background-color: #32852E;
  border-color: #32852E;
  color: #ffffff;
}

.card-success a:not(.btn) {
  color: #333333;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.card-success a:not(.btn):hover, .card-success a:not(.btn):focus {
  color: #000;
  text-decoration: underline;
}

.card-success a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.card-success a:not(.btn) {
  background-image: linear-gradient(to right, #333333 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.card-success a:not(.btn):hover {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  text-decoration: none !important;
}

.card-success .card-header {
  border-bottom: 1px solid #32852E;
  background-color: #2b7227;
}

.card-success .card-footer {
  border-top: 1px solid #32852E;
  background-color: #2b7227;
}

.card-info {
  background-color: #1C7DB5;
  border-color: #1C7DB5;
  color: #ffffff;
}

.card-info a:not(.btn) {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.card-info a:not(.btn):hover, .card-info a:not(.btn):focus {
  color: #fff;
  text-decoration: underline;
}

.card-info a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.card-info a:not(.btn) {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.card-info a:not(.btn):hover {
  background-image: linear-gradient(to right, #fff 50%, transparent 50%);
  text-decoration: none !important;
}

.card-info .card-header {
  border-bottom: 1px solid #1C7DB5;
  background-color: #196e9f;
}

.card-info .card-footer {
  border-top: 1px solid #1C7DB5;
  background-color: #196e9f;
}

.card-warning {
  background-color: #C2570F;
  border-color: #C2570F;
  color: #ffffff;
}

.card-warning a:not(.btn) {
  color: #333333;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.card-warning a:not(.btn):hover, .card-warning a:not(.btn):focus {
  color: #000;
  text-decoration: underline;
}

.card-warning a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.card-warning a:not(.btn) {
  background-image: linear-gradient(to right, #333333 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.card-warning a:not(.btn):hover {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  text-decoration: none !important;
}

.card-warning .card-header {
  border-bottom: 1px solid #C2570F;
  background-color: #aa4c0d;
}

.card-warning .card-footer {
  border-top: 1px solid #C2570F;
  background-color: #aa4c0d;
}

.card-danger {
  background-color: #D24532;
  border-color: #D24532;
  color: #000000;
}

.card-danger a:not(.btn) {
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}

.card-danger a:not(.btn):hover, .card-danger a:not(.btn):focus {
  color: #000;
  text-decoration: underline;
}

.card-danger a:not(.btn):focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.card-danger a:not(.btn) {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.card-danger a:not(.btn):hover {
  background-image: linear-gradient(to right, #000 50%, transparent 50%);
  text-decoration: none !important;
}

.card-danger .card-header {
  border-bottom: 1px solid #D24532;
  background-color: #c03c2a;
}

.card-danger .card-footer {
  border-top: 1px solid #D24532;
  background-color: #c03c2a;
}

.card-inverse .card-header, .card-inverted .card-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.card-inverse .card-footer, .card-inverted .card-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* -----------------------------------------
   ACCENT TEXT
   /source/scss/colorscheme/cs-text-accent.scss
----------------------------------------- */
.text-accent1 {
  color: #1B79C0;
}

.text-accent2 {
  color: #077CBB;
}

.text-accent3 {
  color: #258378;
}

.text-accent4 {
  color: #32852E;
}

.text-accent5 {
  color: #815ab4;
}

.text-accent6 {
  color: #BE37CD;
}

.text-accent7 {
  color: #C93E2C;
}

.text-accent8 {
  color: #BB520C;
}

.text-accent9 {
  color: #f7e455;
}

.text-accent-p1 {
  color: #FDB81E;
}

.text-accent-p2 {
  color: #046B99;
}

.text-accent-p3 {
  color: #323A45;
}

.text-accent-s1 {
  color: #E1F2F7;
}

.text-accent-s2 {
  color: #9FDBF1;
}

.text-accent-s3 {
  color: #50aee4;
}

.color-primary--hover:hover {
  color: #046B99;
}

.bg-primary--hover:hover {
  background-color: #046B99;
}

.label-primary {
  color: #fff;
  background-color: #046B99;
}

.label-info {
  color: #fff;
  background-color: #046B99;
}

.label-secondary {
  color: #333333;
  background-color: #9FDBF1;
}

.label-light {
  color: #333333;
  background-color: #E1F2F7;
}

.label-dark {
  color: #fff;
  background-color: #323A45;
}

.label-warning {
  color: #333333;
  background-color: #FDB81E;
}

/* -----------------------------------------
   IMAGE HELPERS
   /source/scss/cagov/image.scss
----------------------------------------- */
.section-understated .img-thumbnail, .card-understated .img-thumbnail {
  padding: 0.25rem;
  background-color: whitesmoke;
  border: 1px solid #c2c2c2;
}

.section-overstated .img-thumbnail, .card-overstated .img-thumbnail {
  padding: 0.25rem;
  background-color: #b9dff4;
  border: 1px solid #2d9edf;
}

.section-standout .img-thumbnail, .section-impact .img-thumbnail, .card-standout .img-thumbnail, .card-impact .img-thumbnail {
  padding: 0.25rem;
  background-color: #84898f;
  border: 1px solid #21262d;
}

.section-primary .img-thumbnail, .card-primary .img-thumbnail {
  padding: 0.25rem;
  background-color: #b4d3e0;
  border: 1px solid #034867;
}

.section-inverted .img-thumbnail, .card-inverted .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #c2c2c2;
}

.section-danger .img-thumbnail, .card-danger .img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #c2c2c2;
}

.carousel-video-submenu .owl-item.watching img {
  border-color: #FDB81E;
}

.carousel-video-submenu .owl-item.watching button {
  border-color: #FDB81E;
}

/* -----------------------------------------
   COMMENT
   /source/scss/colorscheme/cs-buttons.scss
----------------------------------------- */
.btn-primary {
  color: #fff;
  background-color: #046B99;
  border-color: #046B99;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .btn-primary .show > .dropdown-toggle, .open > .dropdown-toggle .btn-primary {
  color: #fff;
  background-color: #035376;
  border-color: #035376;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .btn-primary {
  background-image: none;
}

.btn-primary.disabled, .btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled:active, .btn-primary.disabled.active, .btn-primary[disabled], .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled]:active, .btn-primary[disabled].active, fieldset[disabled] .btn-primary, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:active, fieldset[disabled] .btn-primary.active {
  background-color: #046B99 !important;
  border-color: #046B99;
}

.btn-primary .badge {
  color: #046B99;
  background-color: #fff;
}

.btn-primary:disabled {
  color: #fff !important;
}

.btn-primary.disabled {
  color: #fff !important;
}

.btn-default {
  color: #000;
  background-color: rgba(255, 255, 255, 0.65);
  border-color: #000;
}

.btn-default:hover, .btn-default:focus, .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .btn-default .show > .dropdown-toggle, .open > .dropdown-toggle .btn-default {
  color: #000;
  background-color: white;
  border-color: #000;
}

.btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .btn-default {
  background-image: none;
}

.btn-default.disabled, .btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled:active, .btn-default.disabled.active, .btn-default[disabled], .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled]:active, .btn-default[disabled].active, fieldset[disabled] .btn-default, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:active, fieldset[disabled] .btn-default.active {
  background-color: rgba(255, 255, 255, 0.65) !important;
  border-color: #000;
}

.btn-default .badge {
  color: rgba(255, 255, 255, 0.65);
  background-color: #000;
}

.btn-standout {
  color: #fff;
  background-color: #323A45;
  border-color: #323A45;
}

.btn-standout:hover, .btn-standout:focus, .btn-standout:not(:disabled):not(.disabled):active, .btn-standout:not(:disabled):not(.disabled).active, .btn-standout .show > .dropdown-toggle, .open > .dropdown-toggle .btn-standout {
  color: #fff;
  background-color: #232930;
  border-color: #232930;
}

.btn-standout:not(:disabled):not(.disabled):active, .btn-standout:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .btn-standout {
  background-image: none;
}

.btn-standout.disabled, .btn-standout.disabled:hover, .btn-standout.disabled:focus, .btn-standout.disabled:active, .btn-standout.disabled.active, .btn-standout[disabled], .btn-standout[disabled]:hover, .btn-standout[disabled]:focus, .btn-standout[disabled]:active, .btn-standout[disabled].active, fieldset[disabled] .btn-standout, fieldset[disabled] .btn-standout:hover, fieldset[disabled] .btn-standout:focus, fieldset[disabled] .btn-standout:active, fieldset[disabled] .btn-standout.active {
  background-color: #323A45 !important;
  border-color: #323A45;
}

.btn-standout .badge {
  color: #323A45;
  background-color: #fff;
}

.btn-standout:disabled {
  color: #fff !important;
}

.btn-standout.disabled {
  color: #fff !important;
}

.btn-highlight {
  color: #000;
  background-color: #FDB81E;
  border-color: #FDB81E;
}

.btn-highlight:hover, .btn-highlight:focus, .btn-highlight:not(:disabled):not(.disabled):active, .btn-highlight:not(:disabled):not(.disabled).active, .btn-highlight .show > .dropdown-toggle, .open > .dropdown-toggle .btn-highlight {
  color: #000;
  background-color: #f5aa02;
  border-color: #f5aa02;
}

.btn-highlight:not(:disabled):not(.disabled):active, .btn-highlight:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .btn-highlight {
  background-image: none;
}

.btn-highlight.disabled, .btn-highlight.disabled:hover, .btn-highlight.disabled:focus, .btn-highlight.disabled:active, .btn-highlight.disabled.active, .btn-highlight[disabled], .btn-highlight[disabled]:hover, .btn-highlight[disabled]:focus, .btn-highlight[disabled]:active, .btn-highlight[disabled].active, fieldset[disabled] .btn-highlight, fieldset[disabled] .btn-highlight:hover, fieldset[disabled] .btn-highlight:focus, fieldset[disabled] .btn-highlight:active, fieldset[disabled] .btn-highlight.active {
  background-color: #FDB81E !important;
  border-color: #FDB81E;
}

.btn-highlight .badge {
  color: #FDB81E;
  background-color: #000;
}

.btn-secondary {
  color: #555555;
  background-color: whitesmoke;
  border-color: #555555;
}

.btn-secondary:hover, .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary .show > .dropdown-toggle, .open > .dropdown-toggle .btn-secondary {
  color: #02374e;
  background-color: #fff;
  border-color: #000;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .open > .dropdown-toggle .btn-secondary {
  background-image: none;
}

.btn-secondary.disabled, .btn-secondary.disabled:hover, .btn-secondary.disabled:focus, .btn-secondary.disabled:active, .btn-secondary.disabled.active, .btn-secondary[disabled], .btn-secondary[disabled]:hover, .btn-secondary[disabled]:focus, .btn-secondary[disabled]:active, .btn-secondary[disabled].active, fieldset[disabled] .btn-secondary, fieldset[disabled] .btn-secondary:hover, fieldset[disabled] .btn-secondary:focus, fieldset[disabled] .btn-secondary:active, fieldset[disabled] .btn-secondary.active {
  background-color: whitesmoke !important;
  border-color: #555555;
}

.btn-secondary .badge {
  color: whitesmoke;
  background-color: #555555;
}

.btn-secondary.active {
  background: #555555;
  color: #fff;
  box-shadow: none;
}

.btn-secondary:disabled {
  color: #000;
}

.btn-secondary.disabled {
  color: #000;
}

.check-icon-radio {
  color: #046B99;
  border-color: #046B99;
}

.check-icon-checkbox {
  border-color: #046B99;
}

input[type="checkbox"]:checked + i,
input[type="radio"]:checked + i,
input[type="checkbox"]:checked + * i,
input[type="radio"]:checked + * i {
  color: #fff;
  background-color: #046B99;
  border-color: #046B99;
}

.bg-primary--checked.checked,
.checked .bg-primary--checked {
  background-color: #046B99 !important;
}

/* -----------------------------------------
   COMMENT
   /source/scss/colorscheme/cs-blocks.scss
----------------------------------------- */
.bg-blue-gradient {
  background-repeat: repeat-x;
  background-image: linear-gradient(225deg, #0aaff9, #046B99);
}

.circle-icon {
  background: #222222;
}

.icon-link:hover, .icon-link:focus {
  color: #FDB81E !important;
}

.service-tile-empty {
  background: #1c1d1e;
  color: #171717;
}

.main-content a.color-gray-dark {
  background-image: linear-gradient(to right, #333333 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

.main-content a.color-primary-hover:hover {
  background-image: linear-gradient(to right, #046B99 50%, transparent 50%);
  background-repeat: repeat-x;
  background-size: 2px 1px;
  background-position: center bottom 4%;
  background-origin: padding-box;
  text-decoration: none !important;
}

/* -----------------------------------------
   HIGH CONTRAST MODE
   /source/scss/cagov/file.scss
----------------------------------------- */
html.high-contrast {
  /* General Reset */
  /* Additional custom overrides */
}

html.high-contrast * {
  background-color: #000 !important;
  background-image: none !important;
  border-color: #ffd600 !important;
  box-shadow: none !important;
  color: #ffd600 !important;
  text-shadow: none !important;
}

html.high-contrast .gauge-detail, html.high-contrast .gauge-number, html.high-contrast .percent-detail {
  background: none !important;
}

html.high-contrast .service-tile .teaser:before {
  border: none !important;
}

html.high-contrast .enableHighContrastMode.active, html.high-contrast .disableHighContrastMode.active {
  background: #ffd600 !important;
  color: #000 !important;
}

html.high-contrast .close {
  opacity: 1;
}

html.high-contrast input[type="checkbox"]:checked + i,
html.high-contrast input[type="radio"]:checked + i,
html.high-contrast input[type="checkbox"]:checked + * i,
html.high-contrast input[type="radio"]:checked + * i {
  color: #000 !important;
  background-color: #ffd600 !important;
  border-color: #ffd600;
}

html.high-contrast .bg-primary--checked.checked,
html.high-contrast .checked .bg-primary--checked {
  background-color: #ffd600 !important;
}

html.high-contrast .check-icon-checkbox {
  color: #000 !important;
  border-color: #ffd600 !important;
}

html.high-contrast .check-icon-checkbox i {
  color: #000 !important;
}

html.high-contrast .select {
  border: 1px solid #ffd600 !important;
}

html.high-contrast .select::after {
  border-left: 1px solid #ffd600 !important;
  background: #000 !important;
}

html.high-contrast .check {
  border: 1px solid #ffd600 !important;
}

html.high-contrast input[type="checkbox"]:checked + .bg-primary--checked,
html.high-contrast input[type="radio"]:checked + .bg-primary--checked,
html.high-contrast input[type="checkbox"]:checked + * .bg-primary--checked,
html.high-contrast input[type="radio"]:checked + * .bg-primary--checked {
  background-color: #ffd600 !important;
  color: #000 !important;
}

html.high-contrast input[type="checkbox"]:checked + .bg-highlight--checked,
html.high-contrast input[type="radio"]:checked + .bg-highlight--checked,
html.high-contrast input[type="checkbox"]:checked + * .bg-highlight--checked,
html.high-contrast input[type="radio"]:checked + * .bg-highlight--checked {
  background-color: #ffd600 !important;
  color: #000 !important;
}

html.high-contrast input[type="checkbox"]:checked + .color-white--checked,
html.high-contrast input[type="radio"]:checked + .color-white--checked,
html.high-contrast input[type="checkbox"]:checked + * .color-white--checked,
html.high-contrast input[type="radio"]:checked + * .color-white--checked {
  color: #000 !important;
}
