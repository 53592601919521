@media screen {
  .disabled {
    .field__checkboxes {
      .btn {
        background: $disabled-bg;
      }

      * {
        cursor: default;
      }

      .check-icon-checkbox {
        color: $disabled-bg;
        border-color: $disabled-accent;
        background: $disabled-bg;
      }

      input[type="checkbox"]:checked {
        + .check-icon-checkbox > i,
        + .btn {
          background: $disabled-fg;
        }
      }
    }
  }
}

@media print {
  input[type="checkbox"] {
    + .check-icon-checkbox > i {
      &::before {
        display: none;
      }
    }

    &:checked {
      + .check-icon-checkbox > i {
        border: 9px solid $brand-primary;
      }

      + .btn {
        border: 1px solid $brand-primary;
      }
    }
  }
}

.has-error {
  .field__checkboxes {
    .check-icon-checkbox {
      border-color: $brand-danger;
    }

    .btn {
      border-color: $brand-danger;
    }
  }
}
