.field__file {
  position: relative;

  input:not(:disabled) {
    &:active,
    &:focus {
      + .field__file-text {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
      }
    }
  }

  .field__file-text {
    margin: 0;
    cursor: pointer;
  }

  .field__file-button {
    position: absolute;
    padding: 0 15px;
    top: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: $btn-primary-bg;
    color: $btn-primary-color;
    line-height: $st-input-height;
  }

  input {
    z-index: -1;
    position: absolute;
    opacity: 0;
  }
}

@media screen {
  .disabled {
    .field__file {
      .field__file-text {
        color: $disabled-fg;
        border-color: $disabled-accent;
        background: $disabled-bg;
        cursor: default;
      }

      .field__file-button {
        display: none;
      }
    }
  }
}

@media print {
  .field__file-button {
    display: none;
  }
}
